<template>
	<div>
		<v-navigation-drawer
			v-model="drawer"
			fixed
			width="600"
			right
			temporary
			stateless
			style="z-index: 99"
		>
			<div class="drawer-wrapper">
				<v-flex class="py-3 px-5 d-flex border-bottom drawer-header white--text orange darken-2">
					<div class="font-level-3-bold my-auto">
						<template v-if="userData.id">Update</template><template v-else>Create</template> User
					</div>
					<v-spacer></v-spacer>
					<v-btn tile color="" class="ml-2" @click="$emit('close')">Cancel</v-btn>
					<v-btn
						tile
						color="blue darken-4"
						class="white--text ml-2"
						@click="update_or_create"
						:loading="pageLoading"
						:disabled="pageLoading || !formValid"
						>Save</v-btn
					>
				</v-flex>
				<v-form
					ref="userForm"
					v-model.trim="formValid"
					lazy-validation
					v-on:submit.stop.prevent="update_or_create"
				>
					<div class="drawer-content pt-5 px-5">
						<div class="d-form-grp mt-4" style="position: relative" v-if="!userData.id">
							<ImageUpload
								can-change
								v-model="users.profile_img"
								:page-loading="pageLoading"
							></ImageUpload>
						</div>
						<div style="height: calc(100vh - 80px); overflow-y: auto">
							<div class="d-form-grp mt-4">
								<label class="mb-2 required">Title </label>
								<v-autocomplete
									:items="userTitle"
									item-text="text"
									item-value="value"
									hide-details
									outlined
									placeholder="Title"
									v-model="users.title"
									:disabled="pageLoading"
									:loading="pageLoading"
									:rules="[vrules.required(users.title, 'Title')]"
									:class="{
										required: !users.title,
									}"
								></v-autocomplete>
							</div>
							<div class="d-form-grp mt-4">
								<label class="mb-2 required">First Name</label>
								<v-text-field
									hide-details
									outlined
									placeholder="First Name"
									v-model="users.first_name"
									:disabled="pageLoading"
									:loading="pageLoading"
									:rules="[vrules.required(users.first_name, 'First Name')]"
									:class="{
										required: !users.first_name,
									}"
								></v-text-field>
							</div>
							<div class="d-form-grp mt-4">
								<label class="mb-2">Last Name</label>
								<v-text-field
									hide-details
									outlined
									placeholder="Last Name"
									v-model="users.last_name"
									:disabled="pageLoading"
									:loading="pageLoading"
								></v-text-field>
							</div>
							<div v-if="getValue(getProfile, 'is_master') == 1" class="d-form-grp mt-4 mb-3">
								<label class="mb-2">User Type</label>
								<v-autocomplete
									:items="users_type_list"
									item-text="title"
									item-value="value"
									hide-details
									outlined
									placeholder="User Type"
									v-model="users.users_type"
									@change="filterUserRoles"
								></v-autocomplete>
							</div>
							<div class="d-form-grp mt-4">
								<label class="mb-2 required">Email</label>
								<v-text-field
									hide-details
									outlined
									placeholder="Email"
									append-icon="mdi-email"
									v-model="users.email"
									:disabled="pageLoading"
									:loading="pageLoading"
									:rules="[vrules.required(users.email, 'Email'), vrules.validEmail(users.email, 'Email')]"
									:class="{
										required: !users.email,
									}"
								></v-text-field>
							</div>
							<div class="d-form-grp mt-4">
								<label class="required">Joining Date</label>
								<DatePicker
									hide-details
									clearable
									:disabled="pageLoading"
									:readonly="
										userData.id &&
										!AllowAnyOforGetRoleNType(['master', 'admin', 'manager', 'super_admin', 'team_lead'])
									"
									:loading="pageLoading"
									id="joining-date"
									placeholder="Joining Date"
									v-model="users.joining_date"
									@change="provisionDate"
									:rules="[vrules.required(users.joining_date, 'Joining Date')]"
									:class="{
										required: !users.joining_date,
									}"
								>
								</DatePicker>
							</div>
							<div class="d-form-grp mt-4">
								<label class="required">Probation Date</label>
								<DatePicker
									hide-details
									clearable
									:readonly="
										userData.id &&
										!AllowAnyOforGetRoleNType(['master', 'admin', 'manager', 'super_admin', 'team_lead'])
									"
									:disabled="pageLoading"
									:loading="pageLoading"
									id="provision-date"
									placeholder="Probation Date"
									v-model="users.provision_date"
									:rules="[vrules.required(users.provision_date, 'Probation Date')]"
									:class="{
										required: !users.provision_date,
									}"
								>
								</DatePicker>
							</div>
							<div class="d-form-grp mt-4">
								<label class="mb-2">Phone Number</label>
								<PhoneTemplate
									ref="userPhoneNumber"
									hide-details
									:disabled="pageLoading"
									:loading="pageLoading"
									id="phone_number"
									v-model="users.phone_number"
									@clear="users.phone_number = null"
									@loading="($event) => (pageLoading = $event)"
									@validity="phoneValidity = $event"
								></PhoneTemplate>
							</div>

							<div class="d-form-grp mt-4">
								<label class="mb-2 required">Role</label>
								<!-- <pre>{{ loginUser }}</pre> -->
								<v-autocomplete
									:items="userRoles"
									item-text="role"
									item-value="id"
									hide-details
									outlined
									placeholder="Role"
									v-model="users.role"
									:readonly="
										userData.id &&
										!AllowAnyOforGetRoleNType(['master', 'admin', 'manager', 'super_admin', 'team_lead'])
									"
									prepend-inner-icon="mdi-account"
									:rules="[vrules.required(users.role, 'Role')]"
									:class="{
										required: !users.role,
									}"
								>
									<template v-slot:item="{ item }">
										<div class="fw-500 py-1 text-capitalize">
											{{ item.role }}
										</div>
									</template>
								</v-autocomplete>
							</div>

							<div class="d-form-grp mt-4 mb-3">
								<label class="mb-2">Designation</label>
								<v-autocomplete
									:items="userProfile"
									item-text="text"
									item-value="value"
									hide-details
									outlined
									:readonly="
										!AllowAnyOforGetRoleNType(['master', 'admin', 'manager', 'super_admin', 'team_lead'])
									"
									placeholder="Designation"
									v-model="users.profile"
								></v-autocomplete>
							</div>
						</div>
					</div>
				</v-form>
			</div>
		</v-navigation-drawer>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import { GET, POST, PUT } from "@/core/services/store/request.module";
import { SET_MESSAGE, SET_ERROR } from "@/core/services/store/common.module";
import MainMixin from "@/core/mixins/main.mixin.js";
import MomentJS from "moment-timezone";
import { toSafeInteger } from "lodash";
import objectPath from "object-path";
import { getCurrentUser } from "@/core/services/jwt.service";

export default {
	mixins: [MainMixin],
	name: "addUser",
	props: {
		drawer: {
			type: Boolean,
			default: false,
		},
		userData: {
			type: Object,
		},
		titleStatus: {
			type: String,
		},
		userType: {
			type: String,
		},
	},
	watch: {
		userData: {
			handler(param) {
				if (param && param.id) {
					this.users.title = param.title;
					this.users.first_name = param.first_name;
					this.users.last_name = param.last_name;
					this.users.email = param.email;
					this.users.joining_date = param.joining_date;
					this.users.provision_date = param.provision_date;
					this.users.role = toSafeInteger(param.role.id);
					this.users.profile = param.profile;
					this.users.users_type = param.users_type;
					this.users.phone_number = param.phone_number;
					if (param.phone_number) {
						this.phoneValidity = true;
					}
				}
			},
		},
		userType: {
			deep: true,
			immediate: true,
			handler(type) {
				if (type) {
					this.users.users_type = type;
				}
			},
		},
	},
	data() {
		return {
			loginUser: null,
			formValid: true,
			pageLoading: false,
			payment_method: null,
			payment_reference: null,
			userRole: [],
			userRoles: [],
			userProfile: [],
			userTitle: [],
			users_type_list: [
				{ title: "SEO", value: "seo" },
				{ title: "Sales", value: "sales" },
				{ title: "Accounts", value: "accounts" },
			],
			users: {
				title: null,
				first_name: null,
				last_name: null,
				email: null,
				phone_number: null,
				role: 0,
				profile: null,
				provision_date: null,
				joining_date: null,
				profile_img: null,
				users_type: null,
			},
			phoneValidity: false,
		};
	},
	components: {
		/*
		PhoneTemplate,
		ImageUpload,
		DatePicker, 
		*/
		PhoneTemplate: () => import("@/view/components/Phone"),
		ImageUpload: () => import("@/view/components/ImageUpload"),
		DatePicker: () => import("@/view/components/DatePicker"),
	},
	methods: {
		hasKey(object, key) {
			return objectPath.has(object, key);
		},
		getValue(object, key) {
			return objectPath.get(object, key);
		},
		update_or_create() {
			const formErrors = this.validateForm(this.$refs["userForm"]);
			this.$refs["userForm"].validate();

			const finalErrors = [...formErrors];
			if (!this.phoneValidity && this.users.phone_number) {
				finalErrors.push({
					model: true,
					message: "Invalid Phone Number.",
					timeout: 2000,
				});

				this.$refs["userPhoneNumber"].focus();
			}

			if (finalErrors.length) {
				this.$store.commit(SET_ERROR, this.errors.concat(finalErrors));
				return false;
			}

			if (!this.$refs["userForm"].validate()) {
				return false;
			}

			if (this.userData && this.userData.id) {
				this.updateUser();
			} else {
				this.createUser();
			}
		},
		provisionDate() {
			let usersParseDate = MomentJS(this.users.joining_date);

			if (this.users.provision_date) {
				/* Avoiding probation date more than joining */
				let difference_of_dates = usersParseDate.diff(MomentJS(this.users.provision_date));
				if (difference_of_dates > 0) {
					this.users.provision_date = usersParseDate.format("YYYY-MM-DD");
				}
			}

			if (this.users.joining_date && !this.users.provision_date) {
				usersParseDate = MomentJS(usersParseDate).add(3, "months");
				return (this.users.provision_date = usersParseDate.format("YYYY-MM-DD"));
			}
		},
		createUser() {
			this.pageLoading = true;
			this.$store
				.dispatch(POST, {
					url: `auth/register`,
					data: this.users,
				})
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{
							model: true,
							message: "Success ! User Created Successfully.",
						},
					]);
					this.users.title = "";
					this.users.first_name = "";
					this.users.last_name = "";
					this.users.email = "";
					this.users.phone_number = "";
					this.users.profile = "";

					this.$emit("close", true);
					this.$emit("refreshUserList", true);
					this.$emit("refreshUser", true);
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		updateUser() {
			this.pageLoading = true;
			this.$store
				.dispatch(PUT, {
					url: `auth/register/${this.userData.id}`,
					data: this.users,
				})
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{
							model: true,
							message: "Success ! User Updated Successfully.",
						},
					]);
					this.users.title = "";
					this.users.first_name = "";
					this.users.last_name = "";
					this.users.email = "";
					this.users.phone_number = "";
					this.users.profile = "";
					this.$emit("close", true);
					this.$emit("refreshUserList", true);
					this.$emit("refreshUser", true);
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		getUserSetting() {
			let currentUserRole = this.loginUser?.users_type;
			//console.log(currentUserRole);
			this.$store
				.dispatch(GET, {
					url: `user-setting?current_user_type=` + currentUserRole,
				})
				.then((data) => {
					// this.userRole = data.role;
					this.userRoles = data.role;
					this.userProfile = data.profile;
					this.userTitle = data.title;
					if (this.getValue(this.users, "users_type")) {
						this.filterUserRoles(this.users.users_type);
					}
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
		filterUserRoles(users_type) {
			this.userRole = this.userRoles.filter((v) => v.role_type == users_type);
		},
	},
	computed: {
		...mapGetters(["errors", "getProfile"]),
	},
	mounted() {
		this.loginUser = getCurrentUser() || "";
		this.getUserSetting();
	},
};
</script>
